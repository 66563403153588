import React from "react";
import { graphql } from "gatsby";
import LocalizedLink from "../components/localizedLink";
import useTranslations from "../components/useTranslations";
import TopSlider from "../components/TopSlider/TopSlider";
import TopAnnounce from "../components/TopAnnounce/TopAnnounce";
import SectionTitle from "../components/SectionTitle/SectionTitle";
import ProductSlider from "../components/ProductSlider/ProductSliderPc";
import ButtonLink from "../components/ButtonLink/ButtonLink";
import FooterNav from "../components/FooterNav/FooterNav";
import Footer from "../components/Footer/Footer";
import TopSliderSp from "../components/TopSlider/TopSliderSp";
import ProductSliderSp from "../components/ProductSlider/ProductSliderSp";
import ProductSliderEnSp from "../components/ProductSlider/ProductSliderEnSp";
import ProductSliderEn from "../components/ProductSlider/ProductSliderEnPc";
import TopSliderEn from "../components/TopSlider/TopSliderEn";
import TopSliderEnSp from "../components/TopSlider/TopSliderEnSp";

const Index = ({ data: { allMdx } }) => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations
	const {
		news,
		productInfo,
		newProducts,
		campaign,
		seeall,
		general,
		events,
	} = useTranslations();
	const pathName =
		typeof window !== "undefined" ? window.location.pathname : "";

	const newsMdx = allMdx.edges.filter(({node: item}) => {
		return item.frontmatter.display !== false
	})

	return (
		<>
			<article className="top">
				{pathName.indexOf("/en") !== -1 ? <TopSliderEn /> : <TopSlider />}
				{pathName.indexOf("/en") !== -1 ? <TopSliderEnSp /> : <TopSliderSp />}
				<TopAnnounce />
				<SectionTitle titleText={`${productInfo}`} />
				{pathName.indexOf("/en") !== -1 ? (
					<ProductSliderEn />
				) : (
					<ProductSlider />
				)}
				{pathName.indexOf("/en") !== -1 ? (
					<ProductSliderEnSp />
				) : (
					<ProductSliderSp />
				)}
				<section className="news">
					<SectionTitle titleText={`${news}`} />
					<ul className="post-list flex between">
						{newsMdx.slice(0, 3).map(({ node: post, index }) => (
							<>
								{post.frontmatter.tag === "exhibition" ? (
									<LocalizedLink
										key={`${post.frontmatter.title}-${post.fields.locale}`}
										className={`news-link`}
										to={`/news/exhibition/`}
									>
										<li>
											<img
												className={`post-thumbnail`}
												src={`${post.frontmatter.featuredImage} `}
												alt=""
											/>
											<div className="tag">
												<p className={`tag tag-exhibition`}>{events}</p>;
											</div>
											<p className="post-title">{post.frontmatter.title}</p>
											<p className="date">{post.frontmatter.date}</p>
										</li>
									</LocalizedLink>
								) : (
									<LocalizedLink
										key={`${post.frontmatter.title}-${post.fields.locale}`}
										className={`news-link`}
										to={`/${post.parent.relativeDirectory}/`}
									>
										<li>
											<img
												className={`post-thumbnail`}
												src={`${post.frontmatter.featuredImage} `}
												alt=""
											/>
											<div className="tag">
												{(() => {
													switch (post.frontmatter.tag) {
														case "newProduct":
															return (
																<p className={`tag tag-new-product`}>
																	{newProducts}
																</p>
															);
														case "campaign":
															return (
																<p className={`tag tag-campaign`}>{campaign}</p>
															);
														case "exhibition":
															return (
																<p className={`tag tag-events`}>{events}</p>
															);
														default:
															return (
																<p className={`tag tag-general`}>{general}</p>
															);
													}
												})()}
											</div>
											<p className="post-title">{post.frontmatter.title}</p>
											<p className="date">{post.frontmatter.date}</p>
										</li>
									</LocalizedLink>
								)}
							</>
						))}
					</ul>
					<ButtonLink className={"margin-auto"} text={seeall} link={"/news"} />
				</section>
			</article>
			<FooterNav />
			<Footer />
		</>
	);
};

export default Index;

export const query = graphql`
	query Index($locale: String!, $dateFormat: String!) {
		allMdx(
			filter: {
				fileAbsolutePath: { regex: "/(sato-corporate-blog/blog)/.*\\\\.mdx$/" }
				fields: { locale: { eq: $locale } }
			}
			sort: { fields: [frontmatter___date], order: DESC }
		) {
			edges {
				node {
					frontmatter {
						title
						date(formatString: $dateFormat)
						featuredImage
						tag
						display
					}
					fields {
						locale
					}
					parent {
						... on File {
							relativeDirectory
						}
					}
				}
			}
		}
	}
`;
