import React from "react"
import {graphql, StaticQuery} from 'gatsby'
import Slider from "react-slick"
import Styles from "./TopSlider.module.scss"
import LocalizedLink from "../localizedLink";
// import slideImg1 from '../../../sato-corporate-blog/TopSlider/slide-1.jpg'
// import slideImg2 from '../../../sato-corporate-blog/TopSlider/slide-2.jpg'
// import slideImg3 from '../../../sato-corporate-blog/TopSlider/slide-3.jpg'
// import slideImg4 from '../../../sato-corporate-blog/TopSlider/slide-4.jpg'
// import slideImgSp1 from '../../../sato-corporate-blog/TopSlider/slide-sp-1.jpg'
// import slideImgSp2 from '../../../sato-corporate-blog/TopSlider/slide-sp-2.jpg'
// import slideImgSp3 from '../../../sato-corporate-blog/TopSlider/slide-sp-3.jpg'
// import slideImgSp4 from '../../../sato-corporate-blog/TopSlider/slide-sp-4.jpg'

const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: '6%',
    arrows: true
};
const spSettings = {
    slidesToShow: 1,
    centerPadding: 0,
    dots: false,
    arrows: false
};
const TopSlider = () => (

        <StaticQuery
            query={
                graphql`
                query topSliderQuery{
                allMdx(
                  filter: {fileAbsolutePath: {regex: "/(TopSliderPc)/.*\\\\.mdx$/"}}
                ){
                  edges{
                    node{
                      frontmatter{
                        link
                        alt
                        image
                       }
                     }
                   }
                 }
                }
              `
            }
            render={(data) => (
                <>
                    <Slider className={`${Styles.slider} pc`} {...settings}>
                        {data.allMdx.edges.map(({node: img, index}) => (
                            <LocalizedLink to={`/${img.frontmatter.link}/`} key={index}>
                                <img className={Styles.slide} src={img.frontmatter.image} alt={img.frontmatter.alt}/>
                            </LocalizedLink>
                        ))}
                    </Slider>
                    <Slider className={'sp'} {...spSettings} >

                    </Slider>
                </>
            )}
        />
)

export default TopSlider